jQuery(document).ready(function ($) { // var rangeValue = $("#dateRange").val();


    $(".filter-widget").on("click", function () {
        $(this).toggleClass('active');
        $(".filters").slideToggle();

    });

    // // Initialize the slider
    // const rangeValue = parseInt($("#date-range-slider").val(), 10);
    // filterMarkersByDateRange(rangeValue);

    // // Debounced function for filtering markers
    // const debouncedFilterMarkers = debounce((value) => {
    //     filterMarkersByDateRange(value);
    // }, 300); // Adjust the debounce delay as needed (300ms in this example)

    // $("#date-range-slider").on("input", function () {
    //     console.log("Slider value changed");
    //     debouncedFilterMarkers($(this).val());
    // });



    $("input[name='likeStatus']").on('change', function (event) {
        let selectedValue = $(this).val();

        if (selectedValue == '1') {
            $("#good-vibes-selected").slideDown();
        } else {
            $("#good-vibes-selected").slideUp();
        }

        // Always show the submit section when a choice is made
        $("#submit-section").show();

        // Enable the submit button
        $("#submit-location").prop("disabled", false);
    });



    function getSelectedVenue() {
        const dropdown = document.getElementById('nearby-bars-dropdown');
        const selectedOption = dropdown.options[dropdown.selectedIndex];

        // Handle unknown venue case
        const isUnknownVenue = selectedOption.value === 'unknown' || !selectedOption.value;

        return {
            placeId: isUnknownVenue ? null : selectedOption.value,
            name: isUnknownVenue ? 'Unknown Venue' : selectedOption.text
        };
    }

    $("#submit-location").on("click", function (event) {
        event.preventDefault();

        var liked = Number($("input[name='likeStatus']:checked").val());
        var music = $("#answer-music").prop("checked") ? 1 : 0;
        var drinks = $("#answer-drinks").prop("checked") ? 1 : 0;

        const selectedVenue = getSelectedVenue(); // Assuming this function retrieves the selected venue details

        // Preparing the data to be posted
        var postData = {
            'latitude': userLocation.lat,
            'longitude': userLocation.lng,
            'liked': liked,
            'music': music,
            'drinks': drinks,
            'place_id': selectedVenue.placeId,
            'venue_name': selectedVenue.name
        };

        $.ajax({
            type: "POST",
            url: "submit_location.php",
            data: postData,
            dataType: "json",
            success: function (response) {
                if (response.success) {
                    // fetchVibesForVisibleVenueMarkers();
                    fetchVibesAndEventsForVisibleVenueMarkers();

                    if (selectedVenue.placeId === "Other" || !selectedVenue.placeId) {
                        const newMarker = {
                            latitude: response.latitude,
                            longitude: response.longitude,
                            liked: Number(response.liked),
                            id: response.id
                        };
                        addMarker(newMarker); // Add the marker on the map for "Other"
                    } else {
                        // Update the existing venue marker based on the place_id
                        updateVenueMarker(selectedVenue.placeId, liked);
                    }

                    // Close all sheets and reset the form
                    // $(".sheet").each(function () {
                    //     setIsSheetShown($(this), false);
                    // });

                    Toastify({
                        text: "💥 Boom! Vibes added ",
                        duration: 5000,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        style: {
                            background: "linear-gradient(to right, #00b09b, #96c93d)"
                        },
                    }).showToast();

                    // Reset the form and hide the "good vibes" section
                    $("#location-form")[0].reset();
                    $("#good-vibes-selected").hide();

                } else {
                    alert("There was an error adding your location.");
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error("Error posting data: ", textStatus, errorThrown);
                alert("There was an error submitting the form.");
            }
        });
    });
});

// Function to update an existing venue marker based on place_id and liked status
function updateVenueMarker(placeId, liked) {
    // Find the marker by placeId and update its properties
    const marker = markers.find(marker => marker.metadata && marker.metadata.place_id === placeId);
    if (marker) {
        // Update marker's metadata if needed
        marker.metadata.liked = liked;

        // Apply any other necessary updates (e.g., icon change, class change, etc.)
        // updateMarkerAppearance(marker);
    }
}


// Event listener for the "Set Location" button
// Check if the URL contains '?debug'
function debug() {
    if (window.location.search.includes('debug')) {
        // Add a class to enable debug mode
        document.body.classList.add('debug-mode');

        // Event listener for the "Set Location" button
        document.getElementById('set-location-btn').addEventListener('click', function () {
            const lat = parseFloat(document.getElementById('latitude-input').value);
            const lng = parseFloat(document.getElementById('longitude-input').value);

            if (!isNaN(lat) && !isNaN(lng)) {
                const manualLocation = { lat, lng };
                map.setCenter(manualLocation);  // Center map on the new location
                fetchBarsNearUser(manualLocation.lat, manualLocation.lng);  // Use the manually entered location
            } else {
                alert('Please enter valid latitude and longitude values.');
            }
        });
    } else {
        // If not in debug mode, use the user's real location
        fetchBarsNearUser();  // Use the default user location
    }
}

// change view using bottom nav
function switchView(targetId, clickedNavItem) {
    // Close any open sheets
    closeAllSheets();

    // Hide all content views and remove 'active' class
    document.querySelectorAll('.content-view').forEach(view => {
        view.setAttribute('aria-hidden', 'true');
        view.classList.remove('active');
    });

    // Show the relevant content view and add 'active' class
    const targetView = document.getElementById(targetId);
    if (targetView) {
        targetView.setAttribute('aria-hidden', 'false');
        targetView.classList.add('active');
    }

    // Update active state of nav items
    document.querySelectorAll('#primary-nav li').forEach(item => {
        item.classList.toggle('active', item === clickedNavItem);
    });

    // If clicked item has 'modal-target' attribute, open the corresponding sheet
    const modalTargetId = clickedNavItem.getAttribute('modal-target');
    if (modalTargetId) {
        const targetSheet = document.getElementById(modalTargetId);
        if (targetSheet) {
            showSheet(targetSheet);
        }
    }
}

// Add event listeners to nav items
document.querySelectorAll('#primary-nav li').forEach(navItem => {
    navItem.addEventListener('click', function() {
        const targetId = this.getAttribute('content-target');
        switchView(targetId, this);
    });
});

// Call switchView to set the initial state (optional)
switchView('content-map', document.getElementById('nav-map'));


// accordion
// Select all accordion triggers

/* JavaScript */
document.addEventListener('DOMContentLoaded', () => {
    const accordionTriggers = document.querySelectorAll('.accordion .trigger');
  
    accordionTriggers.forEach(trigger => {
      trigger.addEventListener('click', function() {
        const targetId = this.getAttribute('data-target');
        const targetContent = document.getElementById(targetId);
        
        this.classList.toggle('active');
        
        if (targetContent) {
          targetContent.classList.toggle('show');
          
          if (targetContent.classList.contains('show')) {
            const customMaxHeight = targetContent.getAttribute('data-mh');
            if (customMaxHeight) {
              targetContent.style.maxHeight = customMaxHeight;
            } else {
              targetContent.style.maxHeight = targetContent.scrollHeight + 'px';
            }
          } else {
            targetContent.style.maxHeight = null;
          }
        }
      });
    });
  });