// map.js
// Global variables
var map;
var markers = []; // Array to hold all markers
let userLocation = null; // User's location
// let userLocation = { lat: 50.9123814, lng: -1.4049011 }; //orange rooms
//let userLocation = { lat: 51.0631103, lng: -1.3169268 };
//let userLocation = { lat: 51.5011343, lng: -0.1118477 }; //waterloo

// uncomment line 36ish when done debugging
let userMarker = null; // Marker for user's location
let nearbyBars = [];

// Default location (used if user denies location access)
//const defaultLocation = { lat: 51.0606892, lng: -1.3131538 }; // winch
//const defaultLocation = { lat: 51.5191715, lng: -0.0963904 }; // london
const defaultLocation = { lat: 51.5011343, lng: -0.1118477 }; // london waterloo


// Handle the results of the nearby search
// Define the desired number of results as a constant
const DESIRED_RESULTS = 20; // Change this value to adjust the number of results


// Function to update the user's location marker on the map
function updateUserMarker(location) {
    if (userMarker) {
        // Update the existing marker's position
        userMarker.position = new google.maps.LatLng(location.lat, location.lng);
        userMarker.map = map; // Ensure marker is on the map
        //console.log('Marker updated to new position:', location);
    } else {
        // Create a new marker if none exists
        addUserLocationMarker();
    }
}


// Initialize the Google Map
function initMap() {
    map = new google.maps.Map(document.getElementById('map'), {
        center: defaultLocation,
        zoom: 14,
        fullscreenControl: false,
        mapId: 'f6b957fb3fe1b7bb',
        zoomControl: false,
        mapTypeControl: false,
        cameraControl: false,
        streetViewControl: false,
        rotateControl: false,
        scaleControl: false,
        keyboardShortcuts: false,
    });

    initCenterControl();

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    // lat: 51.5011343, 
                    // lng: -0.1118477 
                };
                map.setCenter(userLocation);
                addUserLocationMarker();
                fetchBarsNearUser();

            },
            () => {
                handleLocationError(true, defaultLocation);
            }
        );
    } else {
        handleLocationError(false, defaultLocation);
    }

    google.maps.event.addListenerOnce(map, 'idle', () => {
        loadVibeMarkers();
        fetchVibesAndEventsForVisibleVenueMarkers();
        debug();
    });



    // Use the debounced version for fetching vibes when the user interacts with the map
    // steve comment
    map.addListener('idle', debouncedFetchVibes);
}



// Initialize the custom center control functionality
function initCenterControl() {
    const centerControlElement = document.getElementById('center-control');

    centerControlElement.addEventListener('click', () => {
        if (userLocation) {
            map.setCenter(userLocation);
            map.setZoom(14); // Optional: Set a default zoom level when centering
        } else {
            map.setCenter(defaultLocation);
            map.setZoom(14);
        }
    });
}

// Add a marker for the user's location using the AdvancedMarkerElement
function addUserLocationMarker() {
    if (userLocation) {
        if (userMarker) {
            userMarker.map = null; // Remove existing marker
        }

        // Create an advanced marker for the user's location
        const content = document.createElement('div');
        content.innerHTML = `<div class="users-location"></div>`;

        userMarker = new google.maps.marker.AdvancedMarkerElement({
            position: userLocation,
            map: map,
            content: content
        });

        markers.push(userMarker);
    }
}

// Error handling for geolocation
function handleLocationError(browserHasGeolocation, pos) {
    // Set userLocation to defaultLocation if geolocation fails
    userLocation = pos;
    map.setCenter(userLocation);

    // Load markers even if geolocation fails
    fetchBarsNearUser();
    loadAllEvents();
    loadVibeMarkers();

    const infoWindow = new google.maps.InfoWindow({
        position: pos,
        content: browserHasGeolocation
            ? "Error: The Geolocation service failed. Showing default location."
            : "Error: Your browser doesn't support geolocation. Showing default location.",
    });
    //infoWindow.open(map);
}

// Load vibe markers
function loadVibeMarkers() {
    if (typeof filterMarkersByDateRange === 'function') {
        // Filter for the last 2 hours or as required
        filterMarkersByDateRange(2);
    } else {
        console.error("Vibe loading function is not defined.");
    }
}

// Fetch nearby bars and nightclubs using Google Places API
function fetchBarsNearUser(lat = userLocation.lat, lng = userLocation.lng) {
    if (!lat || !lng) {
        console.error('Location not available.');
        return;
    }

    const cacheKey = `nearbyBars_${lat.toFixed(4)}_${lng.toFixed(4)}`;
    const cachedResults = localStorage.getItem(cacheKey);

    if (cachedResults) {
        const { timestamp, results } = JSON.parse(cachedResults);
        
        if (isCacheValid(timestamp)) {
            console.log('Using cached nearby bars results');
            handleSearchResults(results);
            return;
        }
    }

    const service = new google.maps.places.PlacesService(map);
    const radiusOptions = [700, 2000, 5000];
    let currentRadiusIndex = 0;
    let totalResults = [];
    let uniquePlaceIds = new Set(); // Set to keep track of unique place IDs

    function searchWithRadius() {
        if (currentRadiusIndex >= radiusOptions.length) {
            console.log('Search completed. Total unique results:', totalResults.length);
            
            // Cache the results before handling them
            localStorage.setItem(cacheKey, JSON.stringify({
                timestamp: new Date().getTime(),
                results: totalResults
            }));
            
            handleSearchResults(totalResults);
            return;
        }

        const request = {
            location: new google.maps.LatLng(lat, lng),
            radius: radiusOptions[currentRadiusIndex],
            type: 'bar',
            keyword: 'pub',
        };

        service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                console.log(`Found ${results.length} results at radius ${radiusOptions[currentRadiusIndex]}m`);
                
                // Process results to ensure lat and lng are properties and deduplicate
                const newUniqueResults = results.filter(result => {
                    if (!uniquePlaceIds.has(result.place_id)) {
                        uniquePlaceIds.add(result.place_id);
                        return true;
                    }
                    return false;
                }).map(result => ({
                    ...result,
                    geometry: {
                        ...result.geometry,
                        location: {
                            lat: result.geometry.location.lat(),
                            lng: result.geometry.location.lng()
                        }
                    }
                }));
                
                totalResults = totalResults.concat(newUniqueResults);

                if (totalResults.length >= DESIRED_RESULTS) {
                    console.log(`Sufficient unique results found (${totalResults.length}). Stopping search.`);
                    
                    // Cache the results before handling them
                    localStorage.setItem(cacheKey, JSON.stringify({
                        timestamp: new Date().getTime(),
                        results: totalResults
                    }));
                    
                    handleSearchResults(totalResults);
                } else {
                    currentRadiusIndex++;
                    searchWithRadius();
                }
            } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                console.log(`No results at radius ${radiusOptions[currentRadiusIndex]}m. Expanding search.`);
                currentRadiusIndex++;
                searchWithRadius();
            } else {
                console.error('Error in Places API request:', status);
                
                // Cache the results we have so far before handling them
                if (totalResults.length > 0) {
                    localStorage.setItem(cacheKey, JSON.stringify({
                        timestamp: new Date().getTime(),
                        results: totalResults
                    }));
                }
                
                handleSearchResults(totalResults); // Handle whatever results we have so far
            }
        });
    }

    searchWithRadius();
}



function handleSearchResults(results) {
    nearbyBars = results; // Store results for later use
    const barsUl = document.getElementById('barsUl');
    barsUl.innerHTML = ''; // Clear any previous bars

    if (results.length === 0) {
        barsUl.innerHTML = '<li>No venues found nearby. Try expanding your search.</li>';
        console.log('No results found');
        return;
    }

    // Calculate distances for sorting
    results.forEach(feature => {
        const from = new google.maps.LatLng(userLocation.lat, userLocation.lng);
        // Ensure we're using lat and lng as properties, not functions
        const to = new google.maps.LatLng(feature.geometry.location.lat, feature.geometry.location.lng);
        feature.distance = google.maps.geometry.spherical.computeDistanceBetween(from, to) / 1000; // Convert to kilometers
    });

    // Sort results by distance
    results.sort((a, b) => a.distance - b.distance);

    const service = new google.maps.places.PlacesService(map);

    // Display the results (limit to DESIRED_RESULTS)
    results.slice(0, DESIRED_RESULTS).forEach(feature => {
        const venueName = feature.name;
        // Use lat and lng as properties
        const barLat = feature.geometry.location.lat;
        const barLng = feature.geometry.location.lng;

        // Create advanced marker for each venue using only place_id
        createAdvancedMarker({
            type: 'venue',
            latitude: barLat,
            longitude: barLng,
            liked: null, // No like status for new venues
            place_id: feature.place_id, // Use place_id
            name: venueName
        });

        // Get the first photo for the venue, if available
        let photoUrl = 'img/placeholder.png'; // Default placeholder image
        if (feature.photos && feature.photos.length > 0) {
            const cachedPhotoUrl = localStorage.getItem(`photo_${feature.place_id}`);
            if (cachedPhotoUrl) {
                photoUrl = cachedPhotoUrl;
            } else {
                photoUrl = feature.photos[0].getUrl({ maxWidth: 300, maxHeight: 300 });
                localStorage.setItem(`photo_${feature.place_id}`, photoUrl);
            }
        }

        // Create list item with placeholder for open status
        const li = document.createElement('li');
        li.className = 'venue';
        li.dataset.lat = barLat;
        li.dataset.lng = barLng;
        li.dataset.placeId = feature.place_id; // Add place_id to the dataset
        li.innerHTML = `
            <figure class="item-img">
                <img src="${photoUrl}" alt="${venueName}" />
            </figure>
            <div class="item-content">
                <h4 class="item-name">${venueName}</h4>
                <div>
                <span class="distance-display">${feature.distance.toFixed(2)} km</span>
                <span class="open-now-status">Checking status...</span>
                </div>
            </div>
        `;

        li.addEventListener('click', handleVenueListItemClick);
        barsUl.appendChild(li);

        // Use getDetails to fetch the open status
        const request = {
            placeId: feature.place_id,
            fields: ['opening_hours', 'utc_offset_minutes']
        };

        service.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place.opening_hours) {
                const isOpenNow = place.opening_hours.isOpen();
                updateOpenStatus(li, isOpenNow);
            } else {
                updateOpenStatus(li, null);
            }
        });
    });

    // Update nearby bars dropdown
    updateNearbyBarsDropdown();

    console.log(`Displayed ${Math.min(results.length, DESIRED_RESULTS)} out of ${results.length} total unique results`);

    // Add filter functionality
    initializeOpenNowFilter();
}


// Helper function to update open status in the list item
function updateOpenStatus(element, venueData) {
    const statusElement = element.querySelector('.open-now-status');
    if (!statusElement) {
        console.error("Could not find the open-now-status element");
        return;
    }

    let isOpen = null;
    let statusUnknown = true;

    if (typeof venueData === 'boolean') {
        // For list items where we pass a boolean
        isOpen = venueData;
        statusUnknown = false;
    } else if (venueData && venueData.opening_hours) {
        // For venue details
        if (typeof venueData.opening_hours.isOpen === 'function') {
            isOpen = venueData.opening_hours.isOpen();
            statusUnknown = false;
        } else if (Array.isArray(venueData.opening_hours.weekday_text)) {
            // If we have opening hours data, assume it's open (you might want to implement more sophisticated logic here)
            isOpen = true;
            statusUnknown = false;
        }
    }

    if (statusUnknown) {
        statusElement.textContent = 'Status Unknown';
        statusElement.className = 'open-now-status unknown';
    } else if (isOpen) {
        statusElement.textContent = 'Open Now';
        statusElement.className = 'open-now-status open';
    } else {
        statusElement.textContent = 'Closed';
        statusElement.className = 'open-now-status closed';
    }

    if (element.dataset) {
        element.dataset.openNow = statusUnknown ? 'unknown' : (isOpen ? 'true' : 'false');
    }
}
// Modify updateOpeningHoursTitle function
function updateOpeningHoursTitle(venueData) {
    const titleElement = document.querySelector('#venue-details .open-now-status');
    if (!titleElement) {
        console.error("Could not find the opening hours title element");
        return;
    }

    let isOpen = false;
    let statusUnknown = true;

    if (venueData.opening_hours) {
        if (typeof venueData.opening_hours.isOpen === 'function') {
            isOpen = venueData.opening_hours.isOpen();
            statusUnknown = false;
        } else if (Array.isArray(venueData.opening_hours) || 
                   (typeof venueData.opening_hours === 'string' && venueData.opening_hours.includes('day'))) {
            // If we have opening hours data, we can't determine if it's currently open
            // So we'll just show "Opening Hours" instead of Open/Closed status
            titleElement.textContent = 'Opening Hours';
            return;
        }
    }

    if (statusUnknown) {
        titleElement.textContent = 'Opening Hours (Status Unknown)';
        titleElement.className = 'open-now-status unknown';
    } else if (isOpen) {
        titleElement.textContent = 'Open Now';
        titleElement.className = 'open-now-status open';
    } else {
        titleElement.textContent = 'Closed';
        titleElement.className = 'open-now-status closed';
    }
}

function initializeOpenNowFilter() {
    const filterCheckbox = document.getElementById('open-now-filter');
    if (!filterCheckbox) {
        console.error("Could not find the open-now-filter checkbox");
        return;
    }

    filterCheckbox.addEventListener('change', function () {
        const venues = document.querySelectorAll('.venue');
        venues.forEach(venue => {
            if (this.checked) {
                venue.style.display = venue.dataset.openNow === 'true' ? '' : 'none';
            } else {
                venue.style.display = '';
            }
        });
    });
}

// New function to handle list item clicks
function handleVenueListItemClick(event) {
    const listItem = event.currentTarget;
    const placeId = listItem.dataset.placeId;
    
    if (placeId) {
        // Reuse the existing fetchGoogleVenueDetails function
        fetchGoogleVenueDetails(placeId, null, false);
    } else {
        console.error('Place ID not found for the clicked venue');
    }
}