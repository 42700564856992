// How long venues are cached for. Prevents API usage if same area is search and viewed.
//const CACHE_DURATION_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const CACHE_DURATION_MS = 30 * 24 * 60 * 60 * 1000; // 30 days  in milliseconds

// Function to check if cached data is still valid
function isCacheValid(timestamp) {
    const currentTime = new Date().getTime();
    return currentTime - timestamp < CACHE_DURATION_MS;
}


// utils.js
// fix dodgy text characters
function decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}

// Create an advanced marker for a given venue or event
function createAdvancedMarker(markerData) {
    if (!map) {
        console.error("Map is not initialized.");
        return;
    }

    let content;

    if (markerData.type === 'event') {
        const iconUrl = 'img/event-marker.svg';
        const iconSize = { width: 30, height: 30 };
        content = document.createElement('div');
        content.innerHTML = `<img src="${iconUrl}" style="width:${iconSize.width}px; height:${iconSize.height}px;">`;

    } else if (markerData.type === 'vibe') {
        let iconUrl;
        let iconSize = { width: 60, height: 60 };

        if (markerData.liked === 1) {
            iconUrl = 'img/liked-marker.svg';
        } else if (markerData.liked === 0) {
            iconUrl = 'img/unliked-marker.svg';
        }


        content = document.createElement('div');
        content.innerHTML = `<img src="${iconUrl}" style="width:${iconSize.width}px; height:${iconSize.height}px;">`;

    } else {
        // Venue marker logic (default)
        let markerClass = 'venue-marker'; // Default to no additional class
        const vibeScore = markerData.vibeScore || 0;


        // Add vibe-related classes without replacing existing ones
        if (markerData.likes > 0 || markerData.dislikes > 0) {
            if (vibeScore > 0) {
                markerClass += ' positive'; // Add 'positive' class
            } else if (vibeScore < 0) {
                markerClass += ' negative'; // Add 'negative' class
            } else {
                markerClass += ' neutral'; // Add 'neutral' class
            }
        }


        content = document.createElement('div');
        content.className = markerClass;
        content.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 39">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.646 6.946c-1.48-2.305-3.585-4.22-6.053-5.413C19.02.285 16.107-.245 13.267.104A15.203 15.203 0 0 0 5.509 3.39C3.365 5.123 1.75 7.467.835 10.059a14.987 14.987 0 0 0-.086 9.61c.606 1.83 1.666 3.5 2.736 5.085 1.341 1.991 2.802 3.891 4.301 5.763a160.787 160.787 0 0 0 4.106 4.912 133.956 133.956 0 0 0 2.38 2.68c.018.021.035.041.055.06.338.368 1.012.368 1.35 0 .466-.508.922-1.021 1.378-1.534l.007-.008a180.275 180.275 0 0 0 3.309-3.852 130.871 130.871 0 0 0 4.067-5.118c1.203-1.6 2.373-3.237 3.385-4.965.434-.745.826-1.504 1.16-2.301.55-1.313.836-2.731.96-4.144.038-.416.057-.836.057-1.256v-.01c-.014-2.826-.826-5.647-2.354-8.035ZM15.059 19.863a4.806 4.806 0 1 0 0-9.611 4.806 4.806 0 0 0 0 9.611Z"
                    fill="url(#default)" />
                <defs>
                <linearGradient id="default" x1="15" y1="9.131" x2="15" y2="58.949" gradientUnits="userSpaceOnUse"><stop stop-color="#01BBFF"/><stop offset="1" stop-color="#E503A3"/></linearGradient>
                    <linearGradient id="neutral" x1="15" y1="9.13073" x2="15" y2="38" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFD600" />
                        <stop offset="1" stop-color="#FFA800" />
                    </linearGradient>
                    <linearGradient id="negative" x1="15" y1="9.13073" x2="15" y2="58.9493"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EA3D56" />
                        <stop offset="1" stop-color="#8B091C" />
                    </linearGradient>
                    <linearGradient id="positive" x1="15" y1="9.13073" x2="15" y2="58.9493"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#71E409" />
                        <stop offset="1" stop-color="#254E00" />
                    </linearGradient>
                </defs>
            </svg><div class="pulse"></div>`;
    }

    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        map: map,
        content: content,
        title: `Marker ID: ${markerData.place_id || markerData.id}`,
    });

    marker.metadata = {
        type: markerData.type,
        id: markerData.id,
        place_id: markerData.place_id || null,
        liked: markerData.liked || null,
        vibeScore: markerData.vibeScore || 0,
        likes: markerData.likes || 0,        // Added likes count
        dislikes: markerData.dislikes || 0   // Added dislikes count
    };

    marker.addListener('gmp-click', function () {
        //console.log('Marker clicked:', marker.metadata);


        if (marker.metadata.type === 'event') {
            const event = events.find(e => e.id === marker.metadata.id);
            if (event) {
                //showEventDetails(event);
            } else {
                console.error('Event not found for marker:', marker.metadata.id);
            }
        } else if (marker.metadata.type === 'vibe') {
            showVibeDetails(marker.metadata.id);
        } else if (marker.metadata.type === 'venue') {
            $.ajax({
                type: 'GET',
                url: 'get_venue_details.php',
                data: { place_id: marker.metadata.place_id },
                dataType: 'json',
                success: function (response) {
                    if (response.success && response.data) {
                        console.log('Fetched venue details from DB:', response.data);
                        displayVenueDetails(response.data);
                        fetchVibes(marker.metadata.place_id);
                        showVenueSheet(); // Add this line to show the modal
                    } else {
                        console.warn('Venue not found in DB, fetching from Google API');
                        fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
                    }
                },
                error: function (xhr, status, error) {
                    console.error('Error fetching venue details from DB:', error);
                    fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
                }
            });
        } else {
            console.warn('Unknown marker type:', marker.metadata.type);
        }
    });

    markers.push(marker);
}

function showVenueSheet() {
    const venueSheet = document.getElementById('venueSheet');
    if (venueSheet) {
        showSheet(venueSheet);
    }
}


// Function to add a generic marker, used for specific vibe markers
function addMarker(markerData) {
    let iconUrl = 'img/venue-marker.svg'; // Default to venue marker

    if (markerData.liked === 1) {
        iconUrl = 'img/liked-marker.svg';
    } else if (markerData.liked === 0) {
        iconUrl = 'img/unliked-marker.svg';
    }

    // console.log(`Adding marker for ${markerData.name || 'Unknown'} with icon: ${iconUrl}`);

    // Create a content div for the advanced marker
    const content = document.createElement('div');
    content.innerHTML = `<img src="${iconUrl}" style="width:30px; height:30px;">`;

    // Create an AdvancedMarkerElement
    const marker = new google.maps.marker.AdvancedMarkerElement({
        map: map,
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        content: content,
        title: `Marker ID: ${markerData.id}`,
    });

    // Store metadata in marker for reference
    marker.metadata = {
        id: markerData.id,
        name: markerData.name,
        liked: markerData.liked,
    };

    // Add a click listener to show venue details
    marker.addListener('gmp-click', function () {
        map.setCenter(marker.position);
        fetchMarkerDetails(marker.metadata.id, marker);
    });

    markers.push(marker);
}


// Debounce function for the slider
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

// Function to fetch detailed information about a venue using Google Places API
// Optimized Version
// Modify fetchGoogleVenueDetails to work with both markers and list items
function fetchGoogleVenueDetails(placeId, marker, shouldSave = false) {
    const cacheKey = `venueDetails_${placeId}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        
        if (isCacheValid(parsedData.timestamp)) {
            console.log('Using cached venue details');
            displayVenueDetails(parsedData.details);
            showVenueSheet();
            return;
        }
    }

    const service = new google.maps.places.PlacesService(map);

    service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            const { name, formatted_address, geometry, photos, opening_hours, rating } = place;
            const photoUrls = photos ? photos.slice(0, 3).map(photo => photo.getUrl({ maxWidth: 400 })) : [];

            const venueDetails = {
                place_id: placeId,
                name: name,
                address: formatted_address || 'Address not available',
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
                photo_urls: photoUrls,
                opening_hours: opening_hours ? opening_hours.weekday_text : null,
                google_rating: rating || null
            };

            // Cache the data locally
            localStorage.setItem(cacheKey, JSON.stringify({
                timestamp: new Date().getTime(),
                details: venueDetails
            }));

            if (shouldSave) {
                saveVenueToDatabase(venueDetails);
            }

            displayVenueDetails(venueDetails);
            showVenueSheet();
        } else {
            console.error('Failed to fetch venue details:', status);
        }
    });
}

function saveVenueToDatabase(venueDetails) {
    venueDetails.photo_urls = JSON.stringify(venueDetails.photo_urls);
    venueDetails.opening_hours = JSON.stringify(venueDetails.opening_hours);

    $.ajax({
        type: 'POST',
        url: 'save_venue_details.php',
        data: venueDetails,
        success: function (response) {
            console.log('Venue details saved successfully:', response);
        },
        error: function (xhr, status, error) {
            console.error('Error saving venue details:', error);
        }
    });
}

// display venue
function displayVenueDetails(data) {
    if (!data) {
        console.error('Error: No data provided to displayVenueDetails');
        return;
    }

    // Update venue name
    const venueNameElement = document.querySelector('#venue-sheet-venue-name');
    if (venueNameElement) {
        const decodedName = decodeHTMLEntities(data.name || 'Not available');
        venueNameElement.textContent = decodedName;
    }

    // Update Google rating
    const googleRatingElement = document.getElementById('google-rating');
    if (googleRatingElement) {
        googleRatingElement.textContent = data.google_rating || 'Not available';
    }

    // Handle opening hours
    const openingHoursElement = document.getElementById('opening-hours');
    let openingHours = 'Opening hours not available';
    if (data.opening_hours) {
        try {
            if (Array.isArray(data.opening_hours)) {
                openingHours = data.opening_hours.join('<br>');
            } else {
                const parsedOpeningHours = JSON.parse(data.opening_hours);
                if (Array.isArray(parsedOpeningHours)) {
                    openingHours = parsedOpeningHours.join('<br>');
                }
            }
        } catch (error) {
            console.error('Error parsing opening_hours:', error);
        }
    }

    // Update the opening hours title and status
    updateOpeningHoursTitle(data);

    // Display the opening hours
    if (openingHoursElement) {
        openingHoursElement.innerHTML = openingHours;
    }

    // Handle photo URLs
    let photoUrls = [];
    if (typeof data.photo_urls === 'string') {
        try {
            photoUrls = JSON.parse(data.photo_urls);
        } catch (error) {
            console.error('Error parsing photo_urls:', error);
        }
    } else if (Array.isArray(data.photo_urls)) {
        photoUrls = data.photo_urls;
    }

    // Update the image gallery
    updateImageGallery(photoUrls);

    // Fetch events from the AJAX response
    const eventInfoElement = document.getElementById('event-info');
    if (eventInfoElement) {
        fetchEventsForVenue(data.place_id, eventInfoElement);
    }
}


// Declare currentGalleryCleanup at the top of your script or in an appropriate scope
let currentGalleryCleanup = null;

function updateImageGallery(photoUrls) {
    const imageGallery = document.getElementById('venue-image-gallery');
    if (!imageGallery) {
        console.error('Image gallery element not found');
        return;
    }

    imageGallery.innerHTML = ''; // Clear existing images

    if (photoUrls && photoUrls.length > 0) {
        photoUrls.forEach(url => {
            const img = document.createElement('img');
            img.src = url;
            img.alt = 'Venue Image';
            img.className = 'gallery-image';
            imageGallery.appendChild(img);
        });
    } else {
        const img = document.createElement('img');
        img.src = 'img/placeholder.png';
        img.alt = 'Placeholder Image';
        img.className = 'gallery-image';
        imageGallery.appendChild(img);
    }

    // Initialize the gallery functionality
    initializeImageGallery('venue-image-gallery');

   //console.log('Image gallery updated with', photoUrls ? photoUrls.length : 0, 'images');
}

function initializeImageGallery(galleryId) {
    const imageGallery = document.getElementById(galleryId);
    if (!imageGallery) {
        console.error('Image gallery element not found:', galleryId);
        return;
    }

    // Clean up previous gallery if it exists
    if (currentGalleryCleanup) {
        currentGalleryCleanup();
    }

    function createCloseButton() {
        const closeButton = document.createElement('span');
        closeButton.classList.add('close-button');
        closeButton.innerHTML = '&times;';
        return closeButton;
    }

    function handleImageClick(event) {
        const clickedImage = event.target.closest('.gallery-image');
        if (!clickedImage) return;
        
        //console.log('Image clicked:', clickedImage.src);
        
        const existingFullSizeContainer = document.querySelector('.full-size-container');
        
        if (existingFullSizeContainer) {
            //console.log('Closing full-size image');
            closeImage();
        } else {
            //console.log('Opening full-size image');
            openFullSizeImage(clickedImage);
        }
    }

    function openFullSizeImage(image) {
        const fullSizeContainer = document.createElement('div');
        fullSizeContainer.classList.add('full-size-container');
        
        const fullSizeImage = image.cloneNode(true);
        fullSizeImage.classList.add('full-size');
        fullSizeContainer.appendChild(fullSizeImage);
        
        const closeButton = createCloseButton();
        fullSizeContainer.appendChild(closeButton);
        
        document.body.appendChild(fullSizeContainer);
        
        fullSizeContainer.addEventListener('click', (e) => {
            if (e.target === fullSizeContainer || e.target === fullSizeImage) {
                closeImage();
            }
        });
        closeButton.addEventListener('click', closeImage);
    }

    function closeImage() {
        const fullSizeContainer = document.querySelector('.full-size-container');
        if (fullSizeContainer) {
            document.body.removeChild(fullSizeContainer);
            //console.log('Full-size image closed');
        }
    }

    const galleryClickHandler = (event) => {
        if (event.target.closest('.gallery-image')) {
            handleImageClick(event);
        }
    };

    imageGallery.addEventListener('click', galleryClickHandler);

    // Set up the cleanup function
    currentGalleryCleanup = () => {
        imageGallery.removeEventListener('click', galleryClickHandler);
        closeImage(); // Close any open full-size image
        //console.log('Gallery cleaned up:', galleryId);
    };

    //console.log('Image gallery initialized:', galleryId);
}